<template>
  <div>
    <b-card class="mb-0 card-main-filters-and-network w-100">
      <select-network-search 
        :networks_avaibles="networks_avaibles"
        :select_network_dad="select_network"
        @update_select_network="updateSelectNetwork"
        class="select-network-search"
      ></select-network-search>

      <div class="w-100">
        <b-row class="m-0">
          <!-- Audience location-->
          <b-col class="col-12 col-md-6 col-lg filter-outline p-0">
              <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
                <template #button-content> 
                  <div class="d-flex flex-column justify-content-between h-100">
                    <b-form-group class="mb-0">
                      <span class="text-left align-middle color-gray-light d-block mb-1">
                        <span class="search-Map-Pin4"></span> {{$t("search.audience_location")}} 
                      </span>
                    </b-form-group>
                    <p class="text-left m-0">{{text_audience_location}}</p>
                  </div>
                </template>
                <b-dropdown-header :key="update_location" class="b-dropdown-header-location-filter-padding">
                  <div v-for="(location, index) in audience_locations" :key="index">
                    <audience-location-filter
                      :key="change_audience_location"
                      :show_remove="index > 0"
                      :index="index"
                      :location="location"
                      :main_locations_filter="main_locations_filter"
                      @changeLocationAudienceChild="changeLocationAudience"
                      @removeAudienceLocationChild="removeAudienceLocation"
                    />
                  </div>
                  <div v-if="Object.keys(this.audience_locations[0]).length > 0" class="p-custom">
                    <hr/>
                    <b-link v-show="this.audience_locations.length < 3" @click="addAudienceLocation()" class="text-blue">{{$t('search.addLocation')}}</b-link>
                    <div v-show="this.audience_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
                  </div>
                </b-dropdown-header>
              </b-dropdown>
          </b-col>
    
          <!-- Audience size -->
          <b-col cols="12"  class="col-md-6 col-lg filter-outline p-0">
            <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
              <template #button-content>
                <div class="d-flex flex-column justify-content-between h-100">
                  <b-form-group class="mb-0">
                    <span class="text-left align-middle color-gray-light mb-1 d-block">
                      <span class="search-Users"></span> {{$t("search.audienceSize")}}
                    </span>
                  </b-form-group>
                  <p class="text-left m-0">{{text_followers }}</p>
                </div>
              </template>
              <b-dropdown-header class="p-05">
                <followers-filter 
                  @changeFollowers="changeFollowers" 
                  :key="update_followers_filter"
                  :followers_dad="followers_size"
                  ref="followers_filter"
                />
              </b-dropdown-header>
            </b-dropdown>
          </b-col>
    
          <!-- Audience age -->
          <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
            <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
              <template #button-content>
                <div class="d-flex flex-column justify-content-between h-100">
                  <b-form-group class="mb-0">
                    <span class="text-left color-gray-light d-block mb-1">
                      <span class="search-Busy-1"></span>{{$t("search.audience_age")}}
                    </span>
                  </b-form-group>
                  <p class="text-left m-0">{{audience_ages[1] === 65 && audience_ages[0] === 10 ?  $t('search.realty.any') : getAudienceAge()}}</p>
                </div>
              </template>
              <b-dropdown-header class="p-05">
                <div v-if="audience_ages[0] !== 10 && audience_ages[1] === 65"><span class="avenir-medium">≥</span> <strong>{{audience_ages[0]}}</strong> <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
                <div v-else-if="audience_ages[1] !== 65 && audience_ages[0] === 10" class="text-right"><span class="avenir-medium">≤</span> <strong>{{audience_ages[1]}}</strong> <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
                <strong v-else-if="audience_ages[0] === 10 && audience_ages[1] === 65">{{ $t('search.anyAge')}}</strong>
                <div v-else class="d-flex justify-content-between">
                  <div class="">{{audience_ages[0]}} <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
                  <div class="">{{audience_ages[1]}} <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
                </div>
                <slider-range :tooltip="'none'" class="mt-1" :init_range="audience_ages" :interval="1" :max_value="65" :min_value="10" identifier="audience_ages" @changeSlide="changeSlideComponent" :key="audience_ages[0] + audience_ages[1]"/>
                <div class="mb-1 mt-2" v-if="showPercentageAgeAudience()"><span class="avenir-medium">{{$t("search.moreOf")}}</span> {{percentage_audience_age}}% <span class="avenir-medium">{{$t("search.ofAudience")}}</span></div>
                <component
                  :is="vue_slider"
                  v-if="showPercentageAgeAudience()"
                  :tooltip="'none'"
                  v-model="percentage_audience_age"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :max="99"
                  :min="1"
                  :interval="1"
                  @drag-end="changeSlide"
                />
              </b-dropdown-header>
            </b-dropdown>
          </b-col>
    
          <!-- Audience gender -->
          <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
            <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_gender">
              <template #button-content> 
                <div class="d-flex flex-column justify-content-between h-100">
                  <b-form-group class="mb-0">
                    <span class="text-left align-middle color-gray-light mb-1 d-block">
                      <span class="search-intersex"></span> {{$t("search.audience_gender")}} 
                    </span>
                  </b-form-group>
                  <p class="text-left m-0">{{audience_gender !== null ? audience_gender.text : $t('search.realty.any')}}</p>
                </div>
              </template>
              <b-dropdown-header class="avenir-medium p-05">
                <div v-for="(gender, index) in $t('instaGen')" :key="index" ref="gender_dropdown">
                  <div 
                    :class="`button-gender 
                    ${index === 0 ? 'border-radius-top' : ''} 
                    ${index === $t('instaGen').length -1 ? 'border-radius-bottom' : ''}
                    `"
                    @click="setAudienceGender(gender, index)"
                  >
                    {{gender.text}}
                  </div>
                </div>
                
                <div v-if="audience_gender !== null ? audience_gender.value !== null : audience_gender !== null">
                  <div class="mt-2 white-space-wrap avenir-medium">{{$t('search.more_than')}}<strong>{{percentage_gender}}</strong>%{{$t('search.of_audience')}}</div>
                  <component
                    :is="vue_slider"
                    class="mt-1"
                    v-model="percentage_gender"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="100"
                    :min="55"
                    :interval="5"
                    @drag-end="changeSlide"
                    :tooltip="'none'"
                  />
                </div>
              </b-dropdown-header>
            </b-dropdown>
          </b-col>
    
          <!-- Button more filters -->
          <div class="d-flex align-items-center container-more-filters">
            <b-button variant="outline-secondary" class="d-flex align-items-center w-100" @click="open_sidebar = true">
              <b-icon icon="filter" class="mr-03"/> 
              {{$t('search.more_filters')}}
            </b-button>
          </div>
        </b-row>
        <b-sidebar
          v-model="open_sidebar"
          backdrop
          shadow
          right
          width="1000px"
          body-class="background-white"
          no-header
          footer-class="background-white footer-sidebar-filters"
          class="sidebar-filters-insta"
        >
        <feather-icon
            class="close-component"
            icon="XIcon"
            size="16"
            @click="open_sidebar = false"
        />
          <b-row class="p-2">
            <!-- FIlters influencer-->
            <b-col class="col-12 col-md">
              <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
                
              <!-- Gender -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_gender')">
                    <span class="search-intersex"></span><strong> {{$t("search.influencerGender")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_gender"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_gender"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_influencer_gender">
                  <options-filters :options="$t('instaGen')" @optionsFilter="filterGender" :key="update_influencer_gender"></options-filters>
                </div>
              </div>
    
              <!-- Type of account -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('type_account')">
                    <span class="search-User-1"></span><strong> {{$t("search.personalBrand")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_type_account"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_type_account"/>             
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_type_account">
                  <options-filters :options="$t('instaPersonal')" @optionsFilter="filterTypeAccount" :key="update_type_account"></options-filters>
                </div>
              </div>
    
              <!-- Influencer location -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_location')">
                    <span class="search-Map-Pin4"></span><strong> {{$t("search.influencerLocation")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_location"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_location"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1 position-relative" ref="collapse_filters_influencer_location">
                  <b-spinner
                    v-if="loading_location_influencer"
                    variant="primary"
                    label="Text Centered"
                    class="spinner-mention-instagram"
                    />
                  <component
                    :is="vue_autosuggest"
                    v-model="influencer_location"
                    :suggestions="influencer_locations"
                    :input-props="input_locations"
                    :limit="10"
                    :key="change_language"
                    @input="inputInfluencerLocation"
                    :get-suggestion-value="getInfluencerLocation"
                    :should-render-suggestions="shouldRenderSuggestions"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                    </template>
    
                    <template slot="before-suggestions">
                      <div class="m-1" v-if="influencer_location !== ''">
                        {{$t("search.searchingBy")}}: {{influencer_location}}
                      </div>
                      <hr v-if="influencer_location !== ''">
                    </template>
                    <template slot="after-suggestions">
                      <div class="m-1" v-if="no_locations_influencer">
                        {{$t("search.noResults")}}
                      </div>
                    </template>
                  </component>
                </div>
              </div>
    
              <!-- influencer language -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                    <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_influencer_language">
                  <b-form-select
                    v-model="influencer_language"
                    :options="$t('instaIdioma')"
                  />
                </div>
              </div>
    
              <!-- Influencer age  -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_ages')">
                    <span class="search-Busy-1"></span><strong> {{$t("search.influencerAge")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_ages"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_ages"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_influencer_ages">
                  <p class="mt-1 mb-3">{{getLabelAge()}}</p>
                  <slider-range :init_range="influencer_ages" :interval="1" :max_value="65" :min_value="0" identifier="influencer_ages" @changeSlide="changeSlideComponent" :key="influencer_ages[0] + influencer_ages[1]"/>
                </div>
              </div>
    
              <!-- Only verified -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('is_verified')">
                    <span class="search-Check-1"></span><strong> {{$t("search.onlyveri")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_is_verified"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_is_verified"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_is_verified">
                  <options-filters :options="$t('instaVerificado')" @optionsFilter="filterVerified" :key="update_is_verified"></options-filters>
                </div>
              </div>
    
              <!-- Tienen información de contacto -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                    <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_info_contact">
                  <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
                </div>
              </div>
    
              <div v-if="role !== 'espejo'" class="py-2">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('prices')">
                    <strong><b-icon class="search-icon" icon="cash"/>{{$t("search.priceInfluencer")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_prices"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_prices"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_prices">
                  <p class="mb-3">{{getLabelPrices()}}</p>
                  <slider-range :init_range="prices" :interval="10000" :max_value="1000000" :min_value="0" identifier="prices" @changeSlide="changeSlideComponent" :key="prices[0] + prices[1]"/>
                </div>
              </div>
            </b-col>
    
            <b-col class="col-12 col-md">
              <!-- Filters of audience -->
              <div>
                <h4 class="blueC"><strong>{{$t("search.audience")}}</strong></h4>
              </div>
    
              <!-- Audience quality -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('quality_audience')">
                    <strong><b-icon class="search-icon" icon="sort-numeric-up-alt"/>{{$t("search.pointAudience")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_quality_audience"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_quality_audience"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_quality_audience">
                  <b-form-select
                    v-model="quality_audience"
                    :options="$t('qualityAudience')"
                  />
                </div>
              </div>
    
              <!-- Audience interest -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('audience_category')">
                    <span class="search-hashtag"></span><strong> {{$t("search.interestAudience")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_audience_category"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_audience_category"/>   
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_audience_category">
                  <b-form-select
                    v-model="audience_category"
                    :placeholder="$t('search.realty.any')"
                    :options="categories"
                    value-field="title"
                    text-field="title"
                  >
                    <template #first>
                      <b-form-select-option :value="null">{{$t('search.realty.any')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <component
                    :is="vue_slider"
                    class="mt-3"
                    :tooltip="'always'"
                    v-if="audience_category"
                    v-model="percentage_audience_category"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </div>
              </div>
    
              <!-- Audience raze -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('race_audience')">
                    <span class="search-Users"></span><strong> {{$t("search.audienceEthnicity")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_race_audience"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_race_audience"/>   
                  </span>            
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_race_audience">
                  <b-form-select
                    v-model="race_audience"
                    :options="$t('instaEtnia')"
                  />
                  <component
                    :is="vue_slider"
                    class="mt-3"
                    :tooltip="'always'"
                    v-if="race_audience"
                    v-model="percentage_race_audience"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </div>
              </div>
    
              <!-- Audience earn -->
              <div v-if="role !== 'espejo'" class="py-2">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('income')">
                    <strong><b-icon class="search-icon" icon="cash"/>{{$t("search.incomeAudience")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_income"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_income"/>
                  </span>              
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_income">
                  <b-form-select
                    v-model="income"
                    :options="$t('incomeAudience')"
                  />
                  <component
                    :is="vue_slider"
                    class="mt-3"
                    :tooltip="'always'"
                    v-if="income"
                    v-model="percentage_income"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </div>
              </div>
            </b-col>
    
            <b-col class="col-12 col-md">
              <!-- filters performance -->
              <div>
                <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
              </div>
    
              <!-- Seguridad de marca -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('brand_safety')">
                    <strong>{{$t('search.brand_safety')}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_brand_safety"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_brand_safety"/>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_brand_safety">
                  <brand-safety :brand_safety="brand_safety" :key="update_brand_safety" @changeBrandSafety="changeBrandSafety"/>
                </div>
              </div>
    
              <!-- Engagement rate -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('engagement_rate')">
                    <span class="search-Heart-Beat"></span><strong> {{$t("search.engagementRate")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_engagement_rate"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_engagement_rate"/>
                    <div class="my-1" v-if="engagement_rate > 0">{{engagement_rate}} {{$t("search.engagementRate")}}</div>
                  </span>
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_engagement_rate">
                  <component
                    :is="vue_slider"
                    class="mt-3"
                    :tooltip="'always'"
                    v-model="engagement_rate"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="40"
                    :min="0"
                    :interval="0.5"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}'"
                  />
                </div>
              </div>
    
              <!-- Comments rate -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_comments')">
                    <span class="search-Speach-Bubble-6"></span><strong> {{$t("search.averageComments")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_comments"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_comments"/>
                  </span>            
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_average_comments">
                  <b-form-select
                    v-model="average_comments"
                    :options="$t('instaTasaComentarios')"
                    :placeholder="$t('search.realty.any')"
                  />
                </div>
              </div>
    
              <!-- Followers growth -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('follower_days')">
                    <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_follower_days"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_follower_days"/>
                  </span>              
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_follower_days">
                  <b-form-select
                    v-model="followers_days"
                    :options="$t('days')"
                  />
                  <div class="my-1" v-if="followers_days > 0">{{$t("search.increase")}} {{percentage_followers_growth}}%</div>
                  <component
                    :is="vue_slider"
                    :tooltip="'always'"
                    class="mt-3"
                    v-if="followers_days"
                    v-model="percentage_followers_growth"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </div>
              </div>
    
              <!-- Brand mentions -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('mentioned_any')">
                    <span class="search-User-Store"></span><strong> {{$t("search.yt.textMentioned")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_mentioned_any"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_mentioned_any"/>
                  </span>              
                </b-form-group>
                <div class="d-none mt-1" ref="collapse_filters_mentioned_any">
                  <div>
                    <b-form-checkbox v-model="mentioned_any">
                      {{$t('search.yt.hasMentioned')}}
                    </b-form-checkbox>
                  </div>
                  <div class="mt-1">
                    <options-filters :options="$t('optionsMencionado')" @optionsFilter="filterMentionedAny" :key="update_mention"></options-filters>
                    <div class="position-relative">
                      <b-spinner
                        v-if="loading_brands && mention !== null"
                        variant="primary"
                        label="Text Centered"
                        class="spinner-mention-instagram"
                      />
                      <component
                        :is="vue_autosuggest"
                        class="mt-1"
                        v-if="!mentioned_any"
                        :suggestions="brands"
                        :limit="10"
                        :input-props="input_brands"
                        :key="change_language"
                        @input="inputBrands"
                        v-model="brand"
                        :get-suggestion-value="getBrand"
                        :should-render-suggestions="shouldRenderSuggestions"
                      >
                        <template slot-scope="{suggestion}">
                          <chat-contact
                            :user="suggestion.item"
                            tag="li"
                            is-chat-contact
                          />
                        </template>
    
                        <template slot="before-suggestions">
                          <div class="m-1" v-if="brand !== ''">
                            {{$t("search.searchingBy")}}: {{brand}}
                          </div>
                          <hr v-if="brand !== ''">
                        </template>
                        <template slot="after-suggestions">
                          <div class="m-1" v-if="no_brands">
                            {{$t("search.noResults")}}
                          </div>
                        </template>
                      </component>
                    </div>
                  </div>
                </div>
              </div>
    
              <!-- Recent publication -->
              <div class="py-2 border-bottom">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('most_recent')">
                    <span class="search-Calendar"></span><strong> {{$t("search.mostRecent")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_most_recent"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_most_recent"/>
                  </span>              
                </b-form-group>
                <span class="my-1" v-if="most_recent > 0"> {{most_recent}} {{$t("search.days")}}</span>
                <div class="d-none mt-1" ref="collapse_filters_most_recent">
                  <component
                    :is="vue_slider"
                    :tooltip="'always'"
                    class="mt-3"
                    v-model="most_recent"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="100"
                    :min="0"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}'"
                  />
                </div>
              </div>
    
              <!-- Similary to -->
              <div class="py-2">
                <b-form-group class="mb-0">
                  <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('similar')">
                    <span class="search-Eyedropper"></span><strong> {{$t("search.similar")}}</strong>
                    <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_similar"/>
                    <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_similar"/>
                  </span>              
                </b-form-group>
                <div class="d-none mt-1 position-relative" ref="collapse_filters_similar">
                  <b-spinner
                    v-if="loading_similars"
                    variant="primary"
                    label="Text Centered"
                    class="spinner-mention-instagram"
                  />
                  <component
                    :is="vue_autosuggest"
                    v-model="similar"
                    :suggestions="similars"
                    :input-props="input_brands"
                    :key="change_language"
                    :limit="10"
                    @input="inputSimilar"
                    @selected="getSimilarValue"
                    :get-suggestion-value="getValueInput"
                    :should-render-suggestions="shouldRenderSuggestions"
                  >
                    <template slot-scope="{suggestion}">
                      <chat-contact
                        :user="suggestion.item"
                        tag="li"
                        is-chat-contact
                      />
                    </template>
    
                    <template slot="before-suggestions">
                      <div class="m-1" v-if="similar !== ''">
                        {{$t("search.searchingBy")}}: {{similar}}
                      </div>
                      <hr v-if="similar !== ''">
                    </template>
                    <template slot="after-suggestions">
                      <div class="m-1" v-if="no_similars">
                        {{$t("search.noResults")}}
                      </div>
                    </template>
                  </component>
                </div>
              </div>
            </b-col>
          </b-row>
    
          <template #footer>
            <b-col class="button-submit-searcher">
              <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>
    
              <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
                <b-icon icon="trash" class="cursor-pointer"/>
                {{$t('search.reset_filters')}}
              </b-button>
            </b-col>
          </template>
        </b-sidebar>
      </div>
    </b-card>
    <search-by-actions
      :data="{
        total_results,
        loading,
        searched,
        categories
      }"
      @sendFilters="sendFilters()"
      @add_keyword_filter="addKeywordFilter"
      ref="search_by_actions"
      :key="update_search_by"
    ></search-by-actions>

    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel"/>
  </div>
</template>

<script>
import {
  BRow, 
  BCard,
  BCol, 
  BFormGroup, 
  BFormSelect, 
  BButton, 
  BSpinner, 
  BFormSelectOption, 
  BDropdown, 
  BDropdownHeader, 
  BLink,
  BIcon, 
  BSidebar, 
  BFormCheckbox,
  // BBadge,
} from 'bootstrap-vue'
import service from '@/services/search'
import config from '@/services/config'
import { getFiltersUsedLabel, numberFormat, getFormat, trimText } from '@/libs/utils/formats'

export default {
  name: 'InstagramFilter',
  components: {
    BIcon,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BFormSelectOption,
    BSpinner,
    BDropdown,
    BDropdownHeader,
    BLink,
    BSidebar,
    BFormCheckbox,
    ChatContact: () => import('@/views/apps/chat/ChatContact.vue'),
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
    buttonSubmitFilters: () => import('./../buttonSubmitFilters.vue'),
    sliderRange: () => import('@/views/buscador/sliderRange.vue'),
    brandSafety: () => import('./brandSafety.vue'),
    OptionsFilters: () => import('@/views/components/button-group/OptionsFilters.vue'),
    applyFilters: () => import('./applyFilters.vue'),
    FollowersFilter: () => import('@/views/buscador/filters/followers.vue'),
    searchByActions: () => import('./components/searchByActions.vue'),
    selectNetworkSearch: () => import('./components/selectNetworkSearch.vue')
  },
  props: {
    initial_filters: {
      type: Object,
      default: () => {}
    },
    filters_history: {
      type: Object,
      default: () => {}
    },
    networks_avaibles: {
      type: Array,
      default: () => {
        return []
      }
    },
    select_network: {
      type: Object,
      default: () => {
        return {}
      }
    },
    main_locations_filter: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      vue_slider: null,
      vue_autosuggest: null,
      update_mention: false,
      update_info_contact: false,
      update_is_verified: false,
      update_type_account: false,
      update_influencer_gender: false,
      update_dropdown_categories: false,
      update_followers_filter: false,
      update_location: false,
      open_sidebar: false,
      update_brand_safety: false,
      loading: false,
      formatter: v => `${numberFormat(v)}`,
      total_results: 0,
      influencer_gender: null,
      type_account: null,
      influencer_category: [],
      influencer_locations: [],
      cancel_influencer_location: null,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      input_brands: {
        class: 'form-control',
        placeholder: this.$t("search.typeUser")
      },
      influencer_location: '',
      selected_influencer_location: null,
      influencer_language: null,
      influencer_ages: [0, 65],
      is_verified: null,
      has_contacts: null,
      quality_audience: null,
      selected_audience_location: null,
      percentage_audience_location: 10,
      audience_gender: null,
      percentage_gender: 55,
      audience_ages: [10, 65],
      percentage_audience_age: 10,
      audience_category: null,
      percentage_audience_category: 50,
      race_audience: null,
      percentage_race_audience: 50,
      engagement_rate: 0,
      average_comments: null,
      followers_days: null,
      percentage_followers_growth: 50,
      mention: 0,
      brands: [],
      brand: '',
      selected_brand: null,
      cancel_suggests: null,
      most_recent: 0,
      similar: '',
      similars: [],
      selected_similar: null,
      prices: [0, 1000000],
      income: null,
      percentage_income: 50,
      role: 'brand',
      change_language: false,
      loading_location_influencer: false,
      no_locations_influencer: false,
      loading_similars: false,
      no_similars: false,
      loading_brands: false,
      no_brands: false,
      categories: [],
      audience_locations: [{}],
      audience_locations_type: [],
      audience_locations_id: [],
      audience_locations_percentage: [],
      text_audience_location: this.$t('search.realty.any'),
      change_audience_location: false,
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      followers_size: [],
      category: null,
      filters: [
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'audience_locations', value: null, default: [{}]},
            {name: 'text_audience_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'name',
            },
            {
              type: 'string',
              name: ' > ',
            },
            {
              type: 'var',
              name: 'percentage'
            },
            {
              type: 'string',
              name: '%',
            },
          ]
        },
        {
          filter: 'influencer_category',
          text: this.$t("search.category"),
          models: [{name: 'influencer_category', value: null, default: []}],
          value: null,
          multiple_values: true,
          update_component: 'update_dropdown_categories',
          syntax: [
            {
              type: 'var',
              name: 'title',
            },
          ]
        },
        {
          filter: 'audience_ages',
          text: this.$t("search.audienceAge"),
          models: [
            {name: 'audience_ages', value: null, default: [10, 65]},
            {name: 'percentage_audience_age', value: null, default: 10},
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'audience_gender',
          text: this.$t("search.audienceGender"),
          models: [
            {name: 'audience_gender', value: null, default: null},
            {name: 'percentage_gender', value: null, default: 55}
          ],
          update_component: 'update_gender',
          value: null,
          multiple_values: false
        },
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
          multiple_values: false
        },
        {
          filter: 'influencer_gender',
          text: this.$t("search.influencerGender"),
          models: [{name: 'influencer_gender', value: null, default: null}],
          update_component: 'update_influencer_gender',
          value: null,
          multiple_values: false
        },
        {
          filter: 'type_account',
          text: this.$t("search.personalBrand"),
          models: [{name: 'type_account', value: null, default: null}],
          update_component: 'update_type_account',
          value: null,
          multiple_values: false
        },
        {
          filter: 'influencer_location',
          text: this.$t("search.influencerLocation"),
          models: [
            {name: 'influencer_location', value: null, default: ''},
            {name: 'selected_influencer_location', value: null, default: null}
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null, default: null}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'influencer_ages',
          text: this.$t("search.influencerAge"),
          models: [{name: 'influencer_ages', value: null, default: [0, 65]}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'is_verified',
          text: this.$t("search.onlyveri"),
          models: [{name: 'is_verified', value: null, default: null}],
          update_component: 'update_is_verified',
          value: null,
          multiple_values: false
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'has_contacts', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
          multiple_values: false
        },
        {
          filter: 'prices',
          text: this.$t("search.priceInfluencer"),
          models: [{name: 'prices', value: null, default: [0, 1000000]},],
          value: null,
          multiple_values: false
        },
        {
          filter: 'quality_audience',
          text: this.$t("search.pointAudience"),
          models: [{name: 'quality_audience', value: null, default: null}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'audience_category',
          text: this.$t("search.interestAudience"),
          models: [
            {name: 'audience_category', value: null, default: null},
            {name: 'percentage_audience_category', value: null, default: 50},
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'race_audience',
          text: this.$t("search.audienceEthnicity"),
          models: [
            {name: 'race_audience', value: null, default: null},
            {name: 'percentage_race_audience', value: null, default: 50},
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'income',
          text: this.$t("search.incomeAudience"),
          models: [
            {name: 'income', value: null, default: null},
            {name: 'percentage_income', value: null, default: 50},
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'engagement_rate',
          text: this.$t("search.engagementRate"),
          models: [{name: 'engagement_rate', value: null, default: 0},],
          value: null,
          multiple_values: false
        },
        {
          filter: 'average_comments',
          text: this.$t("search.averageComments"),
          models: [{name: 'average_comments', value: null, default: null}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'follower_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'followers_days', value: null, default: null},
            {name: 'percentage_followers_growth', value: null, default: 50}
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'mention',
          text: this.$t("search.account"),
          models: [
            {name: 'mention', value: null, default: 0},
            {name: 'brand', value: null, default: ''}
          ],
          update_component: 'update_mention',
          value: null,
          multiple_values: false
        },
        {
          filter: 'most_recent',
          text: this.$t("search.mostRecent"),
          models: [{name: 'most_recent', value: null, default: 0}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'similar',
          text: this.$t("search.similar"),
          models: [
            {name: 'similar', value: null, default: ''},
            {name: 'selected_similar', value: null, default: null}
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'brand_safety',
          text: this.$t("search.brand_safety"),
          models: [{name: 'brand_safety', value: null, default: []}],
          update_component: 'update_brand_safety',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'str_translate',
              name: 'negativeContent',
            },
          ]
        },
        {
          filter: 'mentioned_any',
          text: `${this.$t('optionsMencionado')[1].text}: ${this.$t('search.realty.any')}`,
          models: [{name: 'mentioned_any', value: null, default: false}],
          value: null,
          multiple_values: false
        },
        {
          filter: 'search_by_influencer',
          text: 'anywhere',
          models: [{name: 'category', value: null, default: ''}],
          update_component: 'update_search_by',
          value: null,
          multiple_values: false
        },
      ],
      searched: true,
      brand_safety: [],
      mentioned_any: false,
      change_apply_filters: false,
      keywords: [],
      where: 'anywhere',
      update_search_by: false,
      network: 'instagram',
      axios: null,
      cancel_search_ws: null,
      typing_timer: null,
      total_categories_service: null,
      loaded_services_history: true,
    }
  },
  computed: {
  },
  async mounted() {
    this.axios = (await import('axios')).default;
    this.vue_slider = (await import('vue-slider-component')).default;
    this.vue_autosuggest = (await import('vue-autosuggest')).VueAutosuggest
    setTimeout(() => {
      if (this.initial_filters && Object.keys(this.initial_filters).length > 0) {
        this.initialSearch()
      } else if (Object.keys(this.filters_history).length === 0) {
        this.getCounter()
      }
    }, 200);
  },
  created () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
    this.getCategories()

    this.searched = true;
  },
  methods: {
    updateSelectNetwork(select_network) {
      this.$emit('update_select_network', select_network)
    },
    getBackgroundNet(network) {
      let color = ''
      if (network === 'instagram') color = '#d6249f'
      if (network === 'twitch') color = '#924DF7'
      if (network === 'youtube') color = '#c4302b'
      if (network === 'tiktok') color = 'black'

      return `background: ${color}`
    },
    initialSearch() {
      let name_country = '';
      const filters = {}
      Object.keys(this.initial_filters).forEach((key) => {
        if (key !== 'st') {
          if (key === 'audience_location') {
            filters['audience_location_id'] = [this.initial_filters[key][0].id]
            filters['audience_location_type'] = [this.initial_filters[key][0].type]
            filters['audience_location_from'] = [this.initial_filters[key][0].prc]
            name_country = this.initial_filters[key][0].name
          } else if (key === 'followers') {
            filters['followers_from'] = this.initial_filters[key][0].from
            filters['followers_to'] = this.initial_filters[key][0].to
          } else if (key === 'followers_growth') {
            filters['followers_growth_period'] = parseInt(this.initial_filters[key][0].id)
            filters['followers_growth_to'] = this.initial_filters[key][0].prc
          } else if (key === 'influencer_category') {
            filters[key] = [parseInt(this.initial_filters[key][0].id.replace('1_', ''))]
          } else if (key === 'mentioned') {
            filters['not_mentioned'] = this.initial_filters[key][0].not
            filters['brands'] = [this.initial_filters[key][0].id]
          } else if (key === 'sort_order') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'sort_type') {
            filters[key] = this.initial_filters[key]
          } else {
            filters[key] = this.initial_filters[key][0].id
          }
        } else {
          filters['st'] = 'ig'
        }
      })

      this.formatApplyFilters(filters, name_country);
      this.$emit('clean_selected');
      this.searched = true
      this.$emit('callInstagram', filters, true)
      this.getCounter(filters)
    },
    formatApplyFilters(filters, name_country) {
      Object.keys(filters).forEach(key => {
        if (key === 'aqs') {
          const filter = this.filters.find(f => f.filter === 'quality_audience')
          filter.models[0].value = filters[key]
          filter.value = filters[key]
          this.quality_audience = filters[key]
        } else if (key === 'audience_location_id') {
        
          const filter = this.filters.find(f => f.filter === 'audience_locations')
          this.audience_locations_id = filters['audience_location_id'];
          this.audience_locations_percentage = filters['audience_location_from']
          this.audience_location_type = filters['audience_location_type']
          this.text_audience_location = filters['audience_location_text'] ? filters['audience_location_text'] : this.$t('search.realty.any')
          const country = this.text_audience_location.split(',').slice(0, -1)
          filter.models[0].value = [{id: filter[key], name: name_country.length === 0 ? country.join(', ') : name_country, percentage: filters['audience_location_from'], type: filters['audience_location_type']}]

          // filter.value = `${name_country} ≥ ${filters['audience_location_from']}`
        } else if (key === 'audience_gender_id') {
          this.audience_gender = this.$t('instaGen').find((i) => i.value === filters[key])
          this.percentage_gender = filters['audience_gender_from'];
          const key_index = this.$t('instaGen').findIndex((i) => i.value === this.audience_gender.value)
          this.setAudienceGender(this.audience_gender, key_index)
        } else if (key.includes('audience_age')) {
          this.percentage_audience_age = filters['audience_age_p'] ? filters['audience_age_p'] : 10;
          this.audience_ages = [filters['audience_age_from'] ? filters['audience_age_from'] : 10, filters['audience_age_to'] ? filters['audience_age_to'] : 65];
        } else if (key === 'followers_from') {
          const filter = this.filters.find(f => f.filter === 'audience_size')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters['followers_to']
          filter.value = `${getFormat(filters[key])}-${getFormat(filters['followers_to'])}`

          this.followers_size = [filters[key], filters['followers_to']]

          if (this.followers_size[0] && this.followers_size[1]) {
            this.text_followers = `${getFormat(this.followers_size[0])}-${getFormat(this.followers_size[1])}`
          } else if (this.followers_from) {
            this.text_followers = `≥${getFormat(this.followers_size[0])}`
          } else if (this.followers_to) {
            this.text_followers = `≤${getFormat(this.followers_size[1])}`
          }
          const filter_component = this.$refs.followers_filter
          if (filter_component) {
            filter_component.from = filters[key];
            filter_component.to = filters['followers_to'];
            this.update_followers_filter = !this.update_followers_filter;
          }
        } else if (key === 'followers_growth_to') {
          const filter = this.filters.find(f => f.filter === 'follower_days')
          const period = parseInt(filters['followers_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          this.followers_days = filters['followers_growth_period'];
          this.percentage_followers_growth = filters['followers_growth_to']
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } else if (key === 'comments_rate') {
          const filter = this.filters.find(f => f.filter === 'average_comments')
          filter.models[0].value = filters['comments_rate']
          filter.value = this.$t('instaTasaComentarios').find((item) => item.value === filters['comments_rate']).text
          this.average_comments = filters['comments_rate']
        } else if (key === 'influencer_category') {

          if (this.categories.length === 0) {
            setTimeout(() => {
              const cat = this.categories.find((item) => item.id === filters[key][0])
              this.influencer_category.push(cat);
              const new_models = [{name: 'influencer_category', value: this.influencer_category}];
              this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
            }, 1000)
          } else {
            const cat = this.categories.find((item) => item.id === filters[key][0])
            this.influencer_category.push(cat);
            const new_models = [{name: 'influencer_category', value: this.influencer_category}];
            this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
          }
        } else if (key === 'brands') {
          const new_models = [
            {name: 'mention', value: 1},
            {name: 'brand', value: filters[key][0]}
          ]
          this.changeFilter('mention', new_models, filters[key][0])
          const filter = this.filters.find(item => item.filter === 'mention')
          filter.text = `${this.$t('optionsMencionado').find((item) => item.value === 0).text}`

          // FILTERS FROM SIDEBAR -----------------
        } else if (key === 'influencer_gender' || key === 'influencer_language' || key === 'is_verified' || key === 'has_contacts') {
          const key_filter = key !== 'has_contacts' ? key : 'info_contact';
          const filter = this.filters.find((f) => f.filter === key_filter)
          filter.models[0].value = filters[key]
          filter.value = filters[key]
          this[key] = filters[key];
        } else if (key === 'influencer_age_from' || key === 'influencer_age_to') {
          const filter = this.filters.find(f => f.filter === 'influencer_ages')
          filter.models[0].value = [
            filters['influencer_age_from'] ? filters['influencer_age_from'] : filter.models[0].default[0],
            filters['influencer_age_to'] ? filters['influencer_age_to'] : filter.models[0].default[1]
          ];
          this.influencer_ages = [
            filters['influencer_age_from'] ? filters['influencer_age_from'] : filter.models[0].default[0],
            filters['influencer_age_to'] ? filters['influencer_age_to'] : filter.models[0].default[1]
          ]
        } else if (key === 'is_personal') {
          const filter = this.filters.find(f => f.filter === 'type_account')
          const val = this.$t('instaPersonal').find((item) => item.value === parseInt(filters[key])).text
          filter.models[0].value = val
          filter.value = val
          this.type_account = filters[key];
        } else if (key === 'blogger_prices_from' || key === 'blogger_prices_to') {
          const filter = this.filters.find(f => f.filter === 'prices')
          filter.models[0].value = [
            filters['blogger_prices_from'] ? filters['blogger_prices_to'] : filter.models[0].default[0],
            filters['blogger_prices_to'] ? filters['blogger_prices_to'] : filter.models[0].default[1]
          ];
          this.prices = [
            filters['blogger_prices_from'] ? filters['blogger_prices_from'] : filter.models[0].default[0],
            filters['blogger_prices_to'] ? filters['influencer_age_to'] : filter.models[0].default[1]
          ]
        } else if (key === 'ig_audience_races_id') {
          this.race_audience = this.$t('instaEtnia').find(i => i.value === filters[key][0]).value
          this.percentage_race_audience = filters['ig_audience_races_from'][0]
        } else if (key === 'ig_audience_incomes') {
          this.income = this.$t('incomeAudience').find(i => i.value === filters[key]).value
          this.percentage_income = filters['audience_incomes_prc'];
        } else if (key === 'brand_safety') {
          const filter = this.filters.find((f) => f.filter === 'brand_safety')
          filter.models[0].value = filters[key]
          this.brand_safety = filters[key];
        } else if (key === 'er') {
          this.engagement_rate = filters[key]
        } else if (key === 'mentioned_any') {
          this.mentioned_any = filters[key]
        } else if (key === 'yt_recent_video_to') {
          this.most_recent = filters[key];
        } else if (key === 'yt_similar') {
          const filter = this.filters.find((f) => f.filter === 'similar')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters[key]
          filter.value = filters[key]

          this.selected_similar = filters[key];
          this.similar = filters[key]
        } else if (key === 'category') {
          const new_models = [{name: 'category', value: filters[key]}];
          this.addKeywordFilter(new_models, filters[key], {category: true})
        } else if (key === 'keywords') {
          filters[key].forEach((i, index) => {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: 'fdsa', default: ''}],
                value: i.keyword,
                multiple_values: false
              },
            )            
          })
        } else if (key === 'influencer_location_id') {
          const filter = this.filters.find(f => f.filter === 'influencer_location')
          filter.models[0].value = filters['influencer_location_text'];
          filter.value = filters['influencer_location_text']
          this.influencer_location_id = filters['influencer_location_id'];
          this.influencer_location_type = filters['influencer_location_type']
          this.influencer_location = filters['influencer_location_text'];
        }
        //--------------------------------------------------
      })
    },
    filterGender(options) {
      this.influencer_gender = options.value
    },
    filterTypeAccount(options) {
      this.type_account = options.value
    },
    filterVerified(options) {
      this.is_verified = options.value
    },
    filterHasContacts(options) {
      this.has_contacts = options.value
    },
    filterMentionedAny(options) {
      this.mentioned_any = options.value
    },
    addKeywordFilter(new_models, keywords, where = {}) {
      if (where.category) { 
        this.keywords = [];
        this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = [];
        this.category = keywords;

        if (keywords.length === 0) {
          new_models[0].value = null;
        } else {
          const filter = this.filters.find(i => i.filter === 'search_by_influencer')
          filter.text = this.$t('search.category');
          this.changeFilter('search_by_influencer', new_models, keywords);
        }
  
        clearTimeout(this.typing_timer);
        this.typing_timer = setTimeout(this.getCounterInputHandleIt, 500);
      } else {
        this.keywords = keywords;
        this.category = null;
        this.getCounter();
        
        if (where.tag_deleted !== null) this.filters = this.filters.filter((i) => i.value !== where.tag_deleted);
        if (where.deleted_all) this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
        keywords.forEach((i, index) => {
          if (!this.filters.find(j => j.value === i.keyword)) {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: null, default: ''}],
                value: null,
                multiple_values: false
              },
            )
            this.changeFilter(`search_by_keywords_${index}`, new_models, i.keyword);
          }
        })
      }
    },
    addCategoryFilter(category) {
      if (this.influencer_category.find((item) => item.id === category.id) === undefined) {
        this.influencer_category.push(category);
        const new_models = [{name: 'influencer_category', value: this.influencer_category}];
        this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
        this.getCounter();
        this.$refs[`word_item_category_${category.id}`][0].classList.add('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.remove('d-none');
      } else {
        this.influencer_category.forEach((item,i) => { if (item.id === category.id) this.influencer_category.splice(i,1) })
        this.$refs[`word_item_category_${category.id}`][0].classList.remove('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.add('d-none');
        this.getCounter();
      }     
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      if (name.includes('search_by_keywords')) {
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = this.$refs.search_by_actions.$refs.search_by_category.keywords_array.filter(i => i.keyword !== to_deleted.value)
        this.keywords = this.keywords.filter(i => i.keyword !== to_deleted.value)
      }

      to_deleted.models.forEach((item) => {
        if (!name.includes('search_by_keywords')) this[item.name] = item.default;
        if (item.name === 'influencer_category') item.default = []
        item.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter);
      if (get_counter) {
        this.getCounter();
      } 
        
      this.searched = true;
      if (name.includes('search_by_keywords')) this.filters = this.filters.filter(i => i.filter !== name)
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    clearFilters(loading_results = true, send_service = true) {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })
      if (send_service) { 
        this.sendFilters(loading_results);
        this.getCounter();
      }

      this.searched = true
      this.open_sidebar = false;
    },
    getCategories() {
      service.getCategories('instagram')
        .then(response => {
          this.total_categories_service = response;
          response.categories.forEach((cat) => {
            const category = {
              id: cat.id,
              title: this.$t(`filters.${cat.title}`)
            }
            this.categories.push(category)
          });
          this.categories.sort((a, b) => (a.title < b.title ? -1 : 1));
        })
        .catch(() => {
        });
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    getRangeAges() {
      if (this.influencer_ages[0] !== 0 && this.influencer_ages[1] !== 65) {
        return {
          'from': this.influencer_ages[0],
          'to': this.influencer_ages[1]
        }
      } else if (this.influencer_ages[0] > 0 && this.influencer_ages[1] === 65) {
        return {
          'from': this.influencer_ages[0],
          'to': null
        }
      } else if (this.influencer_ages[0] === 0 && this.influencer_ages[1] < 65) {
        return {
          'from': null,
          'to': this.influencer_ages[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRangeAgesAudience() {
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) {
        return {
          'from': this.audience_ages[0],
          'to': this.audience_ages[1]
        }
      } else if (this.audience_ages[0] > 10 && this.audience_ages[1] === 65) {
        return {
          'from': this.audience_ages[0],
          'to': null
        }
      } else if (this.audience_ages[0] === 10 && this.audience_ages[1] < 65) {
        return {
          'from': null,
          'to': this.audience_ages[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRangePrice() {
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) {
        return {
          'from': this.prices[0],
          'to': this.prices[1]
        }
      } else if (this.prices[0] > 0 && this.prices[1] === 1000000) {
        return {
          'from': this.prices[0],
          'to': null
        }
      } else if (this.prices[0] === 0 && this.prices[1] < 1000000) {
        return {
          'from': null,
          'to': this.prices[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getCounter (prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          if (response.data.error) {
            this.total_results = 0
            this.loading = false;
            return; 
          }
          if (this.loaded_services_history) this.loaded_services_history = true;
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    getCounterInputHandleIt(prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })
      this.loading = true;
      this.total_results = 0

      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlPhp}/api/v1/buscador/influencers`,
        headers: { 'brandme-key': process.env.VUE_APP_BRANDME_KEY},
        data:{
          ...filters
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.total_results = response.data.data.total ? response.data.data.total : 0
        this.loading = false;
      })
    },
    addAudienceLocation() {
      this.audience_locations.push({})
    },
    changeFollowers(data) {
      this.followers_from = data.from
      this.followers_to = data.to
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${getFormat(this.followers_from)}-${getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${getFormat(this.followers_to)}`
      } else {
        value_model = null
        this.text_followers = this.$t('search.realty.any')
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)
      this.getCounter()
    },
    removeAudienceLocation(location, index, force_remove = false, from_filters = false) {
      const self = this
      setTimeout(function() {
        const is_show = self.$refs.filter_audience_location.$el.querySelector('ul').classList.contains('show')
        if (!from_filters && !is_show && force_remove) self.$refs.filter_audience_location.$el.querySelector('button').click()
      }, 5)
      if (force_remove) this.audience_locations.splice(index, 1)
      else this.audience_locations[index] = {}

      if (!location) return

      if (this.audience_locations.length === 0) {
        this.deleteFilter('audience_locations')
        this.text_audience_location = this.$t('search.realty.any')
      } else {
        let counter_empty = 0
        const location_length = this.audience_locations.filter((item) => item.id).length
        this.audience_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : ''
            this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`
          } else {
            counter_empty += 1;
          }
          if (this.audience_locations.length === counter_empty) {
            this.deleteFilter('audience_locations')
            this.text_audience_location = this.$t('search.realty.any')
          }
        });
      }
      this.update_location = !this.update_location

      this.getCounter()
    },
    changeLocationAudience(data) {
      const index = data.key
      this.audience_locations[index].id = data.location.id
      this.audience_locations[index].type = data.location.type
      this.audience_locations[index].name = data.location.name
      this.audience_locations[index].percentage = data.percentage
      const location_length = this.audience_locations.filter((item) => item.id).length

      this.audience_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
        }
      });
      const new_models = [{name: 'audience_locations', value: this.audience_locations}]
      this.changeFilter('audience_locations', new_models, this.text_audience_location)
      this.getCounter()
    },
    formatAudienceLocations() {
      this.audience_locations_id = []
      this.audience_locations_type = []
      this.audience_locations_percentage = []
      if (this.audience_locations.length > 0) {
        this.audience_locations.forEach(location => {
          if (location.id) {
            this.audience_locations_id.push(location.id)
            this.audience_locations_type.push(location.type)
            this.audience_locations_percentage.push(location.percentage)
          }
        })
      }
    },
    getFilters () {
      const finalFilters = {}
      const ages = this.getRangeAges()
      const audience_ages = this.getRangeAgesAudience()
      const prices = this.getRangePrice()
      this.formatAudienceLocations()
      const data = {
        hash: localStorage.getItem('accessToken'),
        st: 'ig',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        category: this.category,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_gender: this.influencer_gender,
        is_personal: this.type_account,
        influencer_category: this.influencer_category.length !== 0 ? this.influencer_category.map((item) => item.id) : null,
        influencer_location_id: this.selected_influencer_location ? [this.selected_influencer_location.id] : null,
        influencer_location_type: this.selected_influencer_location ? [this.selected_influencer_location.type] : null,
        influencer_location_text: this.influencer_location,
        influencer_language: this.influencer_language,
        influencer_age_from: ages.from,
        influencer_age_to: ages.to,
        is_verified: this.is_verified,
        has_contacts: this.has_contacts,
        aqs: this.quality_audience,
        audience_location_id: this.audience_locations_id.length > 0 ? this.audience_locations_id : null,
        audience_location_type: this.audience_locations_type.length > 0 ? this.audience_locations_type : null,
        audience_location_from: this.audience_locations_percentage.length > 0 ? this.audience_locations_percentage : null,
        audience_location_text: this.text_audience_location !== this.$t('search.realty.any') ? this.text_audience_location : null,
        audience_gender_id: this.audience_gender ? this.audience_gender.value : null,
        audience_gender_from: this.audience_gender ? this.percentage_gender : null,
        audience_age_from: audience_ages.from,
        audience_age_to: audience_ages.to,
        audience_age_p: this.showPercentageAgeAudience() ? this.percentage_audience_age : null,
        audience_category: this.audience_category,
        percentage_audience_category: this.audience_category ? this.percentage_audience_category : null,
        ig_audience_races_id: this.race_audience ?  [this.race_audience] : null,
        ig_audience_races_from: this.race_audience ? [this.percentage_race_audience] : null,
        er: this.engagement_rate > 0 ? this.engagement_rate : null,
        comments_rate: this.average_comments,
        followers_growth_period: this.followers_days,
        followers_growth_to: this.followers_days ? this.percentage_followers_growth : null,
        not_mentioned: !this.mentioned_any && this.selected_brand ? this.mention : null,
        brands: !this.mentioned_any && this.selected_brand ? [this.selected_brand.username] : null,
        yt_recent_video_to: this.most_recent > 0 ? this.most_recent : null,
        yt_similar: this.selected_similar ? this.selected_similar.username : null,
        blogger_prices_from: prices.from,
        blogger_prices_to: prices.to,
        ig_audience_incomes: this.income,
        audience_incomes_prc: this.income ? this.percentage_income : null,
        brand_safety: this.brand_safety.length > 0 ? this.brand_safety : null,
        mentioned_any: this.mentioned_any ? this.mentioned_any : null,
        total_results: this.total_results,
        filters_label_used: getFiltersUsedLabel(this.filters)
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }
      return finalFilters
    },
    sendFilters(loading_results = true) {
      this.$emit('clean_selected');
      const finalFilters = this.getFilters()
      this.searched = true
      this.$emit('callInstagram', finalFilters, loading_results)
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    getAudienceAge() {
      const paragraph = `, ≥ ${this.percentage_audience_age}%`;
      let first = '';
      if (this.audience_ages[1] === 65) first = `≥ ${this.audience_ages[0]}`
      else if (this.audience_ages[0] === 10)  first = `≤ ${this.audience_ages[1]}`
      else first = `${this.audience_ages[0]} - ${this.audience_ages[1]}`;
      return first + paragraph;
    },
    setAudienceGender(gender, index) {
      this.audience_gender = gender;
      this.$refs.gender_dropdown.map((item, i) => {
        if (i === index) item.children[0].classList.add('background-clicked-form');
        else item.children[0].classList.remove('background-clicked-form');
      });
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    inputInfluencerLocation (text) {
      this.influencer_locations = []
      this.no_locations_influencer = false;
      if (text !== '') {
        this.loading_location_influencer = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_influencer_location) this.cancel_influencer_location()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/ubicaciones`,
          data:{
            query: text
          },
          headers: {
            'brandme-key': process.env.VUE_APP_BRANDME_KEY,
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_influencer_location = c;
          })
        })
          .then(result => {
            const { cities, states, countries } = result.data.data
            self.datasuggest = [
              ...cities.map(city => {
                let name = city.name
                name = `${city.name}, ${city.country.name}`
                return {id:city.geonameid, name, type: 1 }
              }),
              ...states.map(state => {
                let name = state.name
                name = `${state.name}, ${state.country.name}`
                return {id:state.geonameid, name, type: 2 }
              }),
              ...countries.map(country => ({id:country.geonameid, name:country.name, type: 0}))
            ]
            const filteredData = self.datasuggest
            self.loading_location_influencer = false;
            self.no_locations_influencer = filteredData.length < 1;
            self.influencer_locations = [{data: filteredData}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              self.loading_location_influencer = false;
              self.no_locations_influencer = true;
            }
            return []
          });
      }
    },
    inputBrands(text) {
      this.brands = []
      this.no_brands = false;
      if (text !== '') {
        this.loading_brands = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_suggests) this.cancel_suggests()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: trimText(text),
            networks: 'instagram',
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel_suggests = c;
          })
        })
          .then(res => {
            this.loading_brands = false;
            this.no_brands = res.data.response.list.length < 1;
            this.brands = [{data: res.data.response.list}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              this.no_brands = true;
              this.loading_brands = false;
            }
          });
      }
    },
    inputSimilar(text) {
      this.similars = [];
      this.no_similars = false;
      if (text !== '') {
        this.loading_similars = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_suggests) this.cancel_suggests()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: trimText(text),
            networks: 'instagram',
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel_suggests = c;
          })
        })
          .then(res => {
            this.no_similars = res.data.response.list.length < 1;
            this.loading_similars = false;
            this.similars = [{data: res.data.response.list}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              this.no_similars = true;
              this.loading_similars = false;
            }
          });
      }
    },
    getSimilarValue(suggestion) {
      this.similar = suggestion.item.username
      this.selected_similar = suggestion.item
      const new_models = [{name: 'similar', value: this.similar}]
      this.changeFilter('similar', new_models, this.similar)
      this.getCounter()
    },
    getValueInput(suggestion) {
      return suggestion.item.username
    },
    getBrand(suggestion) {

      this.brand = suggestion.item.username
      this.selected_brand = suggestion.item
      const new_models = [
        {name: 'mention', value: this.mention},
        {name: 'brand', value: this.brand}
      ]
      this.changeFilter('mention', new_models, this.brand)

      const filter = this.filters.find(item => item.filter === 'mention')
      filter.text = `${this.$t('optionsMencionado').find((item) => item.value === this.mention).text}`
      this.getCounter()
    },
    getInfluencerLocation (suggestion) {
      this.influencer_location = suggestion.item.name
      this.selected_influencer_location = suggestion.item
      const new_models = [{name: 'influencer_location', value: this.influencer_location}]
      this.changeFilter('influencer_location', new_models, this.influencer_location)
      this.getCounter()
    },
    changeSlide() {
      this.getCounter()
    },
    showPercentageAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return false
      return true
    },
    getLabelPrices() {
      if (this.prices[0] === 0 && this.prices[1] === 1000000) return this.$t('search.anyPrice')
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) return `${this.$t("search.between")} $${numberFormat(this.prices[0])} ${this.$t("search.and")} $${numberFormat(this.prices[1])}`
      if (this.prices[0] > 0) return `${this.$t("search.lessThan")} $${numberFormat(this.prices[0])}`
      if (this.prices[1] < 1000000) return `${this.$t("search.greaterThan")} $${numberFormat(this.prices[1])}`

      return this.$t('search.anyPrice')
    },
    getLabelAge() {
      if (this.influencer_ages[0] === 0 && this.influencer_ages[1] === 65) return this.$t("search.anyAge")
      if (this.influencer_ages[0] !== 0 && this.influencer_ages[1] !== 65) return `${this.$t("search.between")} ${this.influencer_ages[0]} ${this.$t("search.and")} ${this.influencer_ages[1]}`
      if (this.influencer_ages[0] > 0) return `${this.$t("search.lessThan")} ${this.influencer_ages[0]}`
      if (this.influencer_ages[1] < 65) return `${this.$t("search.greaterThan")} ${this.influencer_ages[1]}`

      return this.$t("search.anyAge")
    },
    getLabelAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return this.$t("search.anyAge")
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) return `${this.$t("search.between")} ${this.audience_ages[0]} ${this.$t("search.and")} ${this.audience_ages[1]}`
      if (this.audience_ages[0] > 10) return `${this.$t("search.lessThan")} ${this.audience_ages[0]}`
      if (this.audience_ages[1] < 65) return `${this.$t("search.greaterThan")} ${this.audience_ages[1]}`

      return this.$t("search.anyAge")
    },
    changeBrandSafety(brand_safety) {
      this.brand_safety = brand_safety
      const value = brand_safety.length === 0 ? null : this.$t(`negativeContent.${brand_safety[0]}`)

      const new_models = [{name: 'brand_safety', value: brand_safety.length === 0 ? null : brand_safety}]
      this.changeFilter('brand_safety', new_models, brand_safety.length > 1 ? `${value} +${brand_safety.length}` : value)
      this.getCounter()
    },
    changeInfluencerCategory(category, id_deleted) {
      const new_models = [{name: 'influencer_category', value: category}];
      this.$refs[`word_item_category_${id_deleted}`][0].classList.remove('word-break')
      this.$refs[`check_item_category_${id_deleted}`][0].classList.add('d-none')
      this.changeFilter('influencer_category', new_models, null);
      this.getCounter();
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)
      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    },
    formatFiltersHistory(filters) {
      this.loaded_services_history = false
      this.formatApplyFilters(filters, '')
      this.$emit('callInstagram', filters, true)
      this.getCounter(filters)
    }
  },
  watch: {
    loading(val) {
      this.$emit('change_loading', val);
    },
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }
      this.input_brands = {
        class: 'form-control',
        placeholder: this.$t("search.typeUser")
      }

      if (this.audience_locations.length === 1 && !this.audience_locations[0].id) {
        this.text_audience_location = this.$t('search.realty.any')
      }

      if (!this.followers_from && !this.followers_to) this.text_followers = this.$t('search.realty.any')
    },
    filters_history(val, old_val) {
      this.clearFilters(true, false);
      if (val && Object.keys(val).length > 0 && (JSON.stringify(val) !== JSON.stringify(old_val))) {
        this.formatFiltersHistory(val, '')
      }
    },
    audience_ages(val) {
      const new_models = [{name: 'audience_ages', value: val[0] !== 10 || val[1] !== 65 ? val : null}]
      this.changeFilter('audience_ages', new_models, val[0] !== 10 || val[1] !== 65 ? this.getAudienceAge() : this.$t('search.realty.any'))
    },
    percentage_audience_age() {
      this.changeFilter('audience_ages', [], this.getAudienceAge())
    },
    audience_gender(val) {
      const new_models = [{name: 'audience_gender', value: val ? val.value : null}]
      if (val && val.value === null) {
        new_models.push({name: 'percentage_gender', value: 55})
        this.percentage_gender = 55;
      }
      this.changeFilter('audience_gender', new_models, val ? `${val.text}, ≥${this.percentage_gender}%` : null)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_gender(val) {
      this.changeFilter('audience_gender', [], this.audience_gender ? `${this.audience_gender.text}, ≥${val}%` : null)
    },
    influencer_gender(val) {
      const new_models = [{name: 'influencer_gender', value: val ? this.$t('instaGen').find((item) => item.value === val).value : null}]
      this.changeFilter('influencer_gender', new_models, this.$t('instaGen').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    type_account(val) {
      const new_models = [{name: 'type_account', value: val ? this.$t('instaPersonal').find((item) => item.value === val).value : null}]
      this.changeFilter('type_account', new_models, val ? this.$t('instaPersonal').find((item) => item.value === val).text : null)
      if (this.loaded_services_history) this.getCounter()
    },
    influencer_language(val) {
      const new_models = [{name: 'influencer_language', value: val ? this.$t('instaIdioma').find((item) => item.value === val).value : null}]
      this.changeFilter('influencer_language', new_models, val ? this.$t('instaIdioma').find((item) => item.value === val).text : null)
      if (this.loaded_services_history) this.getCounter()
    },
    influencer_ages(val) {
      const new_models = [{name: 'influencer_ages', value: val[0] !== 0 || val[1] !== 65 ? val : null}]
      this.changeFilter('influencer_ages', new_models, val[0] !== 0 || val[1] !== 65 ? this.getLabelAge() : null)
    },
    is_verified(val) {
      const new_models = [{name: 'is_verified', value: this.$t('instaVerificado').find((item) => item.value === val).value}]
      this.changeFilter('is_verified', new_models, this.$t('instaVerificado').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    has_contacts(val) {
      const new_models = [{name: 'has_contacts', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    prices(val) {
      const new_models = [{name: 'prices', value: val[0] === 0 && val[1] === 1000000 ? null : val}]
      this.changeFilter('prices', new_models, val[0] === 0 && val[1] === 1000000 ? null : this.getLabelPrices())
    },
    quality_audience(val) {
      const new_models = [{name: 'quality_audience', value: this.$t('qualityAudience').find((item) => item.value === val).value}]
      this.changeFilter('quality_audience', new_models, this.$t('qualityAudience').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    audience_category(val) {
      const new_models = [{name: 'audience_category', value: val !== null ? val : null}]
      this.changeFilter('audience_category', new_models, val ? `${this.categories.find((item) => item.id === val).title}, ${this.percentage_audience_category}%` : null)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_audience_category(val) {
      this.changeFilter('audience_category', [], `${this.categories.find((item) => item.id === this.audience_category).title}, ${val}%`)
    },
    race_audience(val) {
      const new_models = [{name: 'race_audience', value: this.$t('instaEtnia').find((item) => item.value === val).value}]
      this.changeFilter('race_audience', new_models, `${this.$t('instaEtnia').find((item) => item.value === val).text}, ${this.percentage_race_audience}%`)
    },
    percentage_race_audience(val) {
      this.changeFilter('race_audience', [], `${this.$t('instaEtnia').find((item) => item.value === this.race_audience).text}, ${val}%`)
    },
    income(val) {
      const new_models = [{name: 'income', value: this.$t('incomeAudience').find((item) => item.value === val).value}]
      this.changeFilter('income', new_models, `${this.$t('incomeAudience').find((item) => item.value === val).text}, ${this.percentage_income}%`)
    },
    percentage_income(val) {
      this.changeFilter('income', [], `${this.$t('incomeAudience').find((item) => item.value === this.income).text}, ${val}%`)
    },
    engagement_rate(val) {
      const new_models = [{name: 'engagement_rate', value: val !== 0 ? val : null}]
      this.changeFilter('engagement_rate', new_models, val !== 0 ? val : null)
    },
    average_comments(val) {
      const new_models = [{name: 'average_comments', value: this.$t('instaTasaComentarios').find((item) => item.value === val).value}]
      this.changeFilter('average_comments', new_models, this.$t('instaTasaComentarios').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    followers_days(val) {
      const new_models = [{name: 'followers_days', value: this.$t('days').find((item) => item.value === val).value}]
      this.changeFilter('follower_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ${this.percentage_followers_growth}%`)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_followers_growth(val) {
      this.changeFilter('follower_days', [], `${this.$t('days').find((item) => item.value === this.followers_days).text}, ${val}%`)
    },
    most_recent(val) { 
      const new_models = [{name: 'most_recent', value: val !== 0 ? val : null}]
      this.changeFilter('most_recent', new_models, val !== 0 ? val : null)
    },
    mention(val) {
      if (this.selected_brand) {
        const new_models = [
          {name: 'mention', value: val},
          {name: 'brand', value: this.brand}
        ]
        this.changeFilter('mention', new_models, this.brand)

        const filter = this.filters.find(item => item.filter === 'mention')
        filter.text = `${this.$t('optionsMencionado').find((item) => item.value === val).text}`
        if (this.loaded_services_history) this.getCounter()
      }
      if (this.loaded_services_history) this.getCounter()
    },
    mentioned_any(val) {
      const new_models = [{name: 'mentioned_any', value: val ? val : null}]
      this.changeFilter('mentioned_any', new_models, this.$t('search.realty.any'))

      this.selected_brand = null
      this.brand = ''
      this.brands = []

      const new_models_brand = [
        {name: 'mention', value: null},
        {name: 'brand', value: null}
      ]
      this.changeFilter('mention', new_models_brand, null)
      if (this.loaded_services_history) this.getCounter()
    },
    initial_filters(val) {
      if (Object.keys(val).length > 0) this.initialSearch()
    }
  }
}
</script>
<style lang="scss" scoped>
.p-custom {
  padding: 0 1.28rem 0.5rem 1.28rem;
}
.mr-03 {
  margin-right: 0.3em;
}
.p-05 {
  padding: 0.5em;
}
.container-more-filters {
  @media(max-width: 991px) {
    width: 100%;
    margin-top: 1em;
  }
  @media(min-width: 992px) {
    margin-left: 1em;
  }
}
.card-main-filters-and-network .card-body {
  padding: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  @media(max-width: 991px) {
    flex-wrap: wrap;
    padding: 8px;
  }
}
.button-submit-searcher {
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.select-network-search {
  width: auto;
  @media(max-width: 991px) {
    width: 100% !important;
  }
}
.sidebar-filters-insta > .b-sidebar {
  width: 1000px !important;
}
.spinner-mention-instagram {
  position: absolute;
  top: 4px;
  right: 8px;
}
.dropdown-filters-category > .dropdown-menu {
  top: 3.5em !important;
  left: -1em !important;
  transform: none !important;
}
.word-break {
  word-break: break-all !important;
  font-weight: bolder;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.value-active {
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;

  width: 100%;
  height: 100%;
  background-color: white;
  transition: all .3s;
  border: 2px solid #7367f0;
}
.value-inactive {
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;
  width: 100%;
  height: 100%;
  background-color: gray;
  transition: all .3s;
  border: 2px solid white;
}
.icon-chevron {
  color: rgb(160, 153, 153) ;
}
.spinner-audience {
  position: absolute; 
  right: 5px; 
  top: 5px;
}
.x-icon-autosuggest-filter {
  position: absolute; 
  right: 11px; 
  top: 11px;
}
.w-100 {
  width: 100%;
}
#card-instagram-filters > .card-body {
  padding: 0.7em 1.7em;
}
#card-instagram-filters {
  margin-bottom: 0;
  box-shadow: 0 15px 18px rgb(34 41 47 / 3%) !important;
  border-top-left-radius: 0 !important;
}
</style>
